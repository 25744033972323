<template lang="pug">
q-field.date-filter-field(filled, :label="label", :stack-label="stackLabelCheck()")
  template(slot="prepend")
    inline-svg.datetime-icon(:src="require(`../../../assets/icons/calendar.svg`)")

  datetime(:type="type", v-model="currentFilter", :phrases="phrases")
  q-icon.cancel-date-filter(name="cancel", v-if="isResettable", @click.stop="resetFilter()")
</template>

<script>
import { Datetime } from "vue-datetime";

export default {
  components: {
    Datetime,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      type: this.parentData.data[0].type,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      reset: this.parentData.data[0].reset == undefined ? true : this.parentData.data[0].reset,
      grid: this.parentData.grid,
      path: this.parentData.path,
      phrases: {},
    };
  },

  computed: {
    currentFilter: {
      get() {
        return this.currentFilters[this.name];
      },
      set(value) {
        this.$store.commit("updateFilter", { grid_name: this.grid, filter: this.name, value: value });
      },
    },

    isResettable() {
      return this.currentFilter && this.reset;
    },
  },

  beforeMount() {
    this.setDateFieldLocale();
    this.setDateFieldPhrases();
  },

  methods: {
    resetFilter() {
      // this.currentFilter = undefined
      this.$store.commit("resetFilter", { grid_name: this.grid, filter: this.name });
    },

    stackLabelCheck() {
      return this.currentFilter !== null && this.currentFilter !== undefined && this.currentFilter !== "";
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/filters/date";
</style>
