<template lang="pug">
span.date-viewer {{ formattedDate }}
</template>

<script>
import { parseISO, format } from "date-fns";

export default {
  props: {
    label: String,
  },
  computed: {
    formattedDate() {
      return format(parseISO(this.label), "dd.MM.yyyy");
    },
  },
};
</script>

<style scoped lang="scss">
.date-viewer {
  border: 2px solid #e1e3ec;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 16px 9px;
  font-weight: 700;
  margin-left: 1em;
  color: var(--selected-date-label);
}
</style>
