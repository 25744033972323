<template lang="pug">
q-input.search-all-filter-field(filled, v-model="currentFilter", debounce="500", :label="label")
  q-icon.cancel-search-all-filter(name="cancel", v-if="currentFilter", @click.stop="resetFilter()")
</template>

<script>
export default {
  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
    };
  },

  computed: {
    label() {
      return this.parentData.data[0].label;
    },

    name() {
      return this.parentData.data[0].name;
    },

    currentFilter: {
      get() {
        return this.$store.state.grid[this.grid][this.name];
      },
      set(value) {
        this.$store.commit("updateQuery", { grid_name: this.grid, value: value });
      },
    },
  },

  beforeMount() {},

  methods: {
    resetFilter() {
      this.$emit("search-all-filter-reset");
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/filters/search-all";
</style>
