<template lang="pug">
q-card#form.modal-form-card
  q-card-section
    form-header(
      ref="form-header",
      @close-form="closeFiltersForm",
      :parentData="{ grid: grid, title: title, title_icon: 'filters_header.svg' }"
    )

  .spinner-container.modal-spinner(v-if="loading")
    q-spinner(color="primary", size="3em")

  q-card-section
    q-form(v-show="!loading")
      .row(v-for="row in data.scheme")
        div(v-for="filter in row", :key="filter.name", :class="'col-' + filter.col", style="padding: 5px")
          component(
            :ref="filter.name",
            :is="getFilterComponent(getFilterDataByName(filter.name))",
            @filters-loaded="filtersLoaded",
            @load-options="loadOptions",
            :parentData="{ grid: grid, data: [getFilterDataByName(filter.name)], options_data: options_data }"
          )

  q-card-actions.button-group.row.justify-center(v-show="!loading")
    q-btn.modal-form-cancel(flat, no-caps, :label="buttons['cancel']", @click="closeFiltersForm()")
    q-btn.modal-form-submit(flat, no-caps, :label="buttons['submit']", @click="submitFiltersForm()")
</template>

<script>
import filters from "../filters";
import formHeader from "../../shared/forms/formHeader";

export default {
  components: {
    filters,
    formHeader,
  },

  props: {
    parentData: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      path: this.parentData.path,
      data: this.parentData.data,
      title: this.parentData.header.filters_form.form_header,
      buttons: this.parentData.header.filters_form.form_buttons,

      options_data: this.parentData.options_data,

      loading_filters: [],
      loading: true,
    };
  },

  computed: {},

  watch: {
    loading(newVal, oldVal) {
      if ([true, false].includes(newVal)) {
        this.$refs["form-header"].setLoading(newVal);
      }
    },
  },

  created() {
    this.filtersLoaded();
  },

  methods: {
    setOptionsData(data, filter_name) {
      this.$refs[filter_name][0].setOptionsData(data);
    },

    setLoading(val, filter_name) {
      this.$refs[filter_name][0].setLoading(val);
    },

    loadOptions(attr) {
      this.$emit("load-options", attr);
    },

    getFilterComponent(key) {
      if (key) {
        return filters[key.type];
      }
    },

    getFilterDataByName(filter_name) {
      return this.data.filters.find(f => f.name === filter_name);
    },

    filtersLoaded(filter = undefined) {
      if (this.loading_filters.length === 0) {
        this.loading_filters = this.data.filters
          .filter(f => this.$store.state.fields_with_options.includes(f.type))
          .map(f => [f.type, f.name]);
      }

      if (filter) {
        this.loading_filters = this.loading_filters.filter(f => f.sort().toString() !== filter.sort().toString());
      }

      if (this.loading_filters.length === 0) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },

    closeFiltersForm() {
      this.$emit("close-filters-form");
    },

    submitFiltersForm() {
      this.$emit("submit-filters-form");
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/forms/modal-form";
</style>
